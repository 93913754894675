<template>
  <el-card class="px-16 sm:px-24 py-16">
    <h1 class="font-medium text-lg mb-20">Profile</h1>

    <ValidationObserver ref="createUser" tag="div">
      <div class="flex items-center mb-20">
        <el-input
          v-model="email"
          input-label="E-mail"
          input-class="rounded"
          input-name="email"
          placeholder="Email"
          required
          rules="required|email"
          class="w-1/2 mr-16"
        />
        <el-input
          v-model="full_name"
          input-label="Name"
          input-class="rounded"
          input-name="name"
          placeholder="Name"
          class="w-1/2"
        />
      </div>

      <el-select
        v-model="rolle"
        select-label="Rolle"
        :reduce="role => role.value"
        :options="rolleOptions"
        select-name="rolle"
        required
        rules="required"
        class="flex-1 mb-20"
      />

      <div class="flex flex-col items-start sm:flex-row sm:items-end mb-20">
        <el-input
          v-model="password"
          input-label="Password"
          input-class="rounded"
          input-name="password"
          placeholder="Enter the password"
          required
          rules="required|validate_pass"
          class="sm:mr-16 sm:w-auto mb-10 sm:mb-0 flex-1"
        />

        <el-button :loading="loading" @click="onGeneratePassword">Generate password</el-button>
      </div>
    </ValidationObserver>

    <el-card class="px-16 sm:px-24 py-16 bg-grey-100 border border-grey-200 shadow-none">
      <h4 class="text-base text-grey-500 font-medium leading-big mb-4">Password requirements</h4>

      <ul class="list-disc pl-16 text-grey-300">
        <li>Password must contain a minimum of 8 characters</li>
        <li>Password must contain at least one lower case characters</li>
        <li>Password must contain at least one upper case characters</li>
        <li>Password must contain at least one number</li>
        <li>Password must contain at least one symbol</li>
      </ul>
    </el-card>

    <div class="flex flex-col sm:flex-row sm:items-center mt-20">
      <div class="mb-10 sm:mb-0">
        <p class="font-semibold mb-8">The system can send a welcome email containing login link and password</p>
        <el-checkbox v-model="isSendMail" :disabled="loading">Send welcome mail</el-checkbox>
      </div>

      <el-button type="primary" :loading="loading" class="ml-auto" @click="onSave">Save</el-button>
    </div>
  </el-card>
</template>

<script>
import { generatePassword } from '@/core/helper-functions'
import { mapActions } from 'vuex'

const ElCard = () => import('@/components/shared/ELCard')
const ElInput = () => import('@/components/shared/ELInput')
const ElSelect = () => import('@/components/shared/ELSelect')
const ElButton = () => import('@/components/shared/ELButton')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'UserCreateComponent',

  components: { ElCard, ElInput, ElSelect, ElButton, ElCheckbox },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      email: '',
      rolle: '',
      full_name: '',
      password: '',
      isSendMail: false,
      rolleOptions: [
        { label: 'Administrator', value: 'admin' },
        { label: 'Advertiser', value: 'advertiser' }
      ]
    }
  },

  methods: {
    ...mapActions(['showNotification']),

    onGeneratePassword () {
      this.password = generatePassword()
    },

    onSave () {
      this.$refs.createUser.validate().then(isValid => {
        if (!isValid) {
          const errors = []

          // generate array of arrows
          Object.keys(this.$refs.createUser.errors).map(error => {
            errors.push(...this.$refs.createUser.errors[error])
          })

          this.showNotification({ type: 'error', message: errors })
          return false
        }
        this.$emit('on-create-user', { email: this.email, role: this.rolle, password: this.password, isNotify: this.isSendMail, full_name: this.full_name })
      })
    }
  }
}
</script>
